<!--
 * @Description: 政策项目申报
 * @Author: LXG
 * @Date: 2021-04-27
 * @Editors: LXG
 * @LastEditTime: 2021-06-26
-->
<template>
  <div class="policy-declaration">
    <!-- banner -->
    <div class="banner-size">
      <!-- <div class="banner-inner">
                <span class="banner-fMax">申报详情</span>
                <span class="banner-fMin">送政策、送服务，助推企业发展</span>
            </div> -->
    </div>
    <div class="def-container marg-tb-2 pd-content">
      <div class="pd-title">
        {{ policyData.name || projectData.sxmc }}
        <el-button
          class="btn"
          type="primary"
          @click="formDownload"
          v-if="templateInfo.templateFlag && stepInfo.active === 0"
          >表单下载</el-button
        >
      </div>
      <el-steps
        class="pd-steps"
        :active="stepInfo.active"
        finish-status="success"
        process-status="finish"
        align-center
      >
        <el-step
          v-for="item in stepInfo.list"
          :key="item.label"
          :title="item.label"
        ></el-step>
      </el-steps>
      <div class="container">
        <!-- 申请须知 -->
        <!-- <div v-show="stepInfo.active == 0" class="step-one-content">
          <Enterprise ref="enterprise" v-model="enterpriseData"></Enterprise>
          <<Agent ref="agent" v-model="agentData"></Agent> -->
          <!-- Agent ref="agent" v-model="agentData"></Agent>
          <Notice ref="notice"></Notice>
        </div> -->
        <!-- 填写信息 -->
        <div
          class="step-finishInfo"
          v-show="stepInfo.active == 0"
          id="downloadForm"
        >
<!--          v-if="!isPrint"-->
          <Project ref="project" v-model="moreData" ></Project>
<!--          <ProjectDownload v-model="moreData" v-else></ProjectDownload>-->
          <!-- 新增两个字段 -->
          <!-- <el-form
            v-show="isSpecialProject == 'false'"
            :model="newForm"
            :rules="rules"
            ref="otherProjectInfo"
            label-position="left"
          >
            <el-form-item
              label-width="220px"
              label="该政策是否有其他项目需要申报"
              prop="otherFlag"
            >
              <el-radio-group v-model="newForm.otherFlag">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="填写本次申报项目名称"
              label-width="170px"
              v-if="newForm.otherFlag"
              prop="otherPorject"
            >
              <el-input v-model="newForm.otherPorject"></el-input>
            </el-form-item>
          </el-form> -->
          <!-- <bankAccount ref="bank" v-model="bankData"></bankAccount> -->
        </div>
        <!-- 上传材料 -->
        <div v-show="stepInfo.active == 1" class="step-confirm step-confirm2">
          <Material2 v-model="materialData" v-if="isSpecialProject=='false'"></Material2>
          <MaterialWithTitle v-model="materialData" v-else></MaterialWithTitle>
        </div>
        <!-- 确认信息 -->
        <div v-if="stepInfo.active == 2" class="step-confirm step-confirm2">
          <!-- <EnterpriseTxt v-model="enterpriseData" disabled></EnterpriseTxt> -->
          <AgentTxt v-model="agentData" disabled></AgentTxt>
          <bankAccountTxt
            ref="agent"
            v-model="bankData"
            disabled
          ></bankAccountTxt>
          <ProjectTxt
            class="confirm-project"
            v-model="moreData"
            disabled
          ></ProjectTxt>
          <!-- 新增的两个字段 -->
          <!-- <el-form
            v-show="isSpecialProject == 'false'"
            :model="newFormCache"
            label-position="left"
          >
            <el-form-item
              label-width="220px"
              label="该政策是否有其他项目需要申报"
              prop="otherFlag"
            >
              <el-radio-group v-model="newFormCache.otherFlag" disabled>
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              label="本次申报项目名称"
              label-width="170px"
              v-if="newFormCache.otherFlag"
              prop="otherPorject"
            >
              <el-input
                disabled
                v-model="newFormCache.otherPorject"
                style="border: none"
              ></el-input>
            </el-form-item>
          </el-form> -->
          <Material v-model="materialData" disabled v-if="isSpecialProject=='false'"></Material>
          <MaterialWithTitle v-model="materialData" disabled v-else> </MaterialWithTitle>
        </div>
        <!-- 提交成功 -->
        <!-- <div v-show="stepInfo.active == 4" class="step-success">
          <div class="step-success-icon">
            <i class="el-icon-success"></i>
          </div>
          <p class="step-success-text">
            申报成功！您的业务编号{{ newProjectId }}政策申报，待预审，您可以登录
            <span>德阳市惠企政策通</span>
            或
            <span>德阳市惠企政策通</span>
            查看业务办理最新进度。
          </p>
          <el-button type="primary" @click="goWorkplace"
            >返回个人工作台</el-button
          >
        </div> -->
      </div>
      <div class="footBtns">
        <el-button
          v-for="(item, index) in cpu_footBtns"
          :key="index"
          :type="item.type"
          @click="clickFootBtn(item)"
          >{{ item.label }}</el-button
        >
      </div>
    </div>
    <embed
      v-if="wordVisible"
      :src="docViewUrl + templateInfo.templateFileId + '/download'"
      width="100%"
      height="1000"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Enterprise from "./components/enterprise/Enterprise";
// import EnterpriseTxt from "./components/enterprise/EnterpriseTxt";
// import Agent from "./components/agent/Agent";
// import bankAccount from "./components/agent/bankAccount";
import bankAccountTxt from "./components/agent/bankAccountTxt";
import AgentTxt from "./components/agent/AgentTxt";
import Notice from "./components/notice/Notice";
import Project from "./components/project/Project";
import ProjectTxt from "./components/project/ProjectTxt";
import ProjectDownload from "./components/project/ProjectDownload";
import Material from "./components/material/Material";
import Material2 from "./components/material/test";
import MaterialWithTitle from "./components/material/MaterialWithTitle";
// import { decrypt } from '@/utils/aes.js'
export default {
  name: "PolicyDeclaration",
  components: {
    // Agent,
    AgentTxt,
    // bankAccount,
    bankAccountTxt,
    Project,
    ProjectTxt,
    // ProjectDownload,
    Material2,
    Material,
    MaterialWithTitle,
    // EnterpriseTxt
  },
  data() {
    return {
      isSpecialProject: "", //是否为两个特殊项目
      templateInfo: {}, //项目的表单模板信息
      wordVisible: false,
      pdfUrl:
        process.env.VUE_APP_BASE_API +
        "/common/pdfView?file=" +
        process.env.VUE_APP_BASE_API +
        "/common/fileView/",
      docViewUrl: process.env.VUE_APP_DATA_URL + "/common/fileView/",
      isPrint: false,
      zancunsxid: "", //暂存件的事项id
      showTempSave: false, //控制暂存按钮的显示与隐藏
      tempSaveFlag: false, //点击暂存
      isStepPlus: false, //判断是否是点击下一步
      projectId: "", // 政策项目ID
      projectData: {}, // 政策项目信息(暂存、退回等状态用)
      policyData: {}, // 政策信息
      companyData: {}, // 企业信息
      stepInfo: {
        active: 0,
        list: [
          // { label: "申请告知" },
          { label: "填写信息" },
          { label: "上传材料" },
          { label: "确认信息" },
          // { label: "提交成功" },
        ],
      },
      // 申请企业信息
      enterpriseData: {
        unitname: "", // 名称
        unitcardtype: "100008", // 证件类型
        unitcardcode: "", // 证件号码
        unitaddress: "", // 地址
        legalperson: "", // 法人姓名
        legalcardtype: "100001", // 法人证件类型
        legalcardnum: "", // 法人证件号码
        unitemail: "", // 企业电子邮箱
        unitpostcode: "", // 企业邮政编码
        unittel: "", // 企业联系电话
      },
      bankData: {
        bank: "", // 账户名称
        ssyh: "006", // 所属银行
        zhmc: "", // 支行名称
        account: "", // 银行卡号
        phone: "", //联系电话
      },
      // 经办人信息
      agentData: {
        agentname: "", // 姓名
        agentcertificatetype: "100001", // 证件类型
        agentcertificatenumber: "", // 证件号码
        agentmobilephone: "", // 联系电话
        agentaddress: "", // 联系地址
        agentbirthday: "", // 出生日期
        agentcertificatename: "身份证", // 证件名称
        agentemail: "", // 电子邮箱
        agentgender: "", // 性别
        agentuserid: "", // 用户ID
        zw: "", //职务
        lxrlx: "", //联系人类型
        qq: "", //qq
        gddh: "", //固定电话
        wxh: "", //微信号
        ssdq: "", //所属地区
        chz: "", //传真号
      },
      // 动态表单信息(更多信息)
      moreData: {
        bsnum: "", // 业务流水号
        formdata: "", // 表单结构JSON
        formid: "", // 表单ID
        formtype: "0", // 表单类型{'0':业务表单}
        id: "", // 业务表单ID(新申报是为空)
        subdata: "", // 表单数据JSON
      },
      materialData: [], // 材料信息
      countdown: 10, // 倒计时返回
      backTimer: null, // 计时器
      newProjectId: "", // 新申报的项目ID
      newForm: {
        otherFlag: "",
        otherPorject: "",
      },
      newFormCache: {
        otherFlag: "",
        otherPorject: "",
      },
      // rules: {
      //   otherFlag: [
      //     {
      //       required: true,
      //       message: "请选择政策是否有其他项目需要申报",
      //       trigger: "change",
      //     },
      //   ],
      //   otherPorject: [
      //     {
      //       required: true,
      //       message: "请填写本次申报项目名称",
      //       trigger: "change",
      //     },
      //   ],
      // },
      qysxList:[], //企业list
      enterpriseAttibute_user:""
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
    /**
     * @description 底部按钮组
     */
    cpu_footBtns() {
      let list = [];
      if (
        0 < this.stepInfo.active &&
        this.stepInfo.active < this.stepInfo.list.length - 1
      ) {
        list = [{ label: "上一步", type: "", methodName: "lastStep" }];
      }
      if (this.stepInfo.active < this.stepInfo.list.length - 1) {
        list.push({ label: "下一步", type: "primary", methodName: "nextStep" });
        // 申报的时候才有暂存按钮，改为暂存件也可暂存
        if (this.stepInfo.active == 0 || this.stepInfo.active == 0) {
          list.push({ label: "暂存", type: "primary", methodName: "tempSave" });
        }
      }
      if (this.stepInfo.active === this.stepInfo.list.length - 1) {
        list.push({ label: "上一步", type: "", methodName: "lastStep" });
        list.push({
          label: "暂存信息",
          type: "primary",
          methodName: "tempSave",
        });
        list.push({ label: "确认上传", type: "cyan", methodName: "submit" });
      }
      return list;
    },
    /**
     * @description: 政策ID(事项ID)
     * @return {String}
     */
    cpu_policyId() {
      if (!this.projectId) {
        return this.policyData.id;
      } else {
        return this.projectData.sxid;
      }
    },
  },
  methods: {
    // 获取企业属性字典
    getEnterpriseAttribute() {
      let urlid = "enterprise_attributes";
      if (urlid) {
        this.$httpApi
          .get(`/dev-api/system/dict/getDictListByType?dictType=${urlid}`, {})
          .then((res) => {
            if (res.code == 200) {
              this.qysxList = res.data;
              this.getEnterpriseList();
            }
          })
          .catch((err) => {});
      }
    }, // 获取用户中心填写企业属性
    getEnterpriseList() {
      let url = "/dev-api/enterprise/baseInfo/list";
      let params = {
        pageSize: 10, // 页大小
        pageNum: 1, // 页码
        shxydm: this.userInfo.tyshxydm,
      };
      let _that = this;
      this.$httpApi
        .get(url, params)
        .then((res) => {
          if (res.code === 200 && res.rows.length > 0) {
            if (res.rows[0].logoMogoId) {
              let tempForm = res.rows[0].logoMogoId;
              let tempForm1 = res.rows[0];
              if (tempForm === null) {
                Object.keys(tempForm1).map((key) => {
                  if (key === "logoMogoId") {
                    delete tempForm1.logoMogoId;
                    tempForm = tempForm1;
                  }
                });
              }
              let target1 = JSON.parse(tempForm);
              for (const [k, v] of Object.entries(target1)) {
                if (v == null) {
                  target1[k] = "";
                }
              }
              this.enterpriseAttibute_user = target1.enterpriseAttributes;
              let temp = "";
              this.qysxList.forEach((item) => {
                if (item.key == this.enterpriseAttibute_user) {
                  temp = item.label;
                }
              }); // 将用户中心填写的企业属性存进session
              sessionStorage.setItem("enterpriseAttibute_user", temp);
            }
          }
        })
        .catch((err) => {});
    },
    // 表单下载
    formDownload() {
      this.wordVisible = true;
      setTimeout(() => {
        this.wordVisible = false;
      }, 500);
    },
    //删除暂存件（修改暂存件并提交成功）
    updateZancun() {
      let url = "/dev-api/workplace/deleteZancun";
      let param = {
        zcid: this.$route.query.pId,
      };
      this.$httpApi
        .post(url, param)
        .then((res) => {
          res;
        })
        .catch();
    },
    // 获取暂存数据
    getZancunData(zcid) {
      let url = "/dev-api/workplace/queryZancun";
      let params = {
        zcid: zcid,
      };
      this.$httpApi
        .post(url, params)
        .then((res) => {
          this.newForm.otherFlag = Number(res.data.otherFlag);
          this.newForm.otherPorject = res.data.otherPorject;
          this.zancunsxid = res.data.enterpriseData.sxid;
          this.agentData = res.data.agentData;
          this.enterpriseData = res.data.enterpriseData;
          this.moreData = res.data.moreData;
          return this.getPolicyData(this.zancunsxid);
        })
        .catch((e) => {
          console.log("zancun err", e);
        });
    },
    clickFootBtn(item) {
      this[item.methodName]();
    },
    /**
     * @description 上一步
     */
    lastStep() {
      this.stepInfo.active--;
    },
    //第一个和第二个步骤点击暂存的时候表单验证
    async formCheck() {
      switch (this.stepInfo.active) {
        case 0:
          /*   this.$refs["agent"].$refs["form"].validate((valid) => {
            if (!valid) {
              this.$message.warning("请填写完整的信息");
              return false;
            }
            // if (!this.$refs["notice"].checked) {
            //   this.$message.warning("请阅读并同意《服务协议》");
            //   return false;
            // }
          }); */
          // this.$refs["bank"].$refs["form"].validate((valid) => {
          //   if (!valid) {
          //     this.$message.warning("请填写完整的信息");
          //     return false;
          //   }
          // });
          // 验证新增的两个字段
          // let flag = true;
          // this.$refs.otherProjectInfo.validate((valid) => {
          //   if (valid) {
          //     console.log(this.newForm);
          //   } else {
          //     flag = false;
          //   }
          // });
          // if (!flag) {
          //   return;
          // }
          break;
        case 1:
          let newFormData = await this.$refs["project"].$refs[
            "parseFormViewDiv"
          ].getFormData();
          if (!newFormData) {
            this.$message.warning("请检查填写的表单信息");
            return false;
          }
          break;
        default:
          break;
      }
      return true;
    },
    /**
     * @description 下一步
     */
    async nextStep() {
      let that = this;
      this.newFormCache = this.newForm;
      switch (this.stepInfo.active) {
        // case 0:
        //   this.$refs["agent"].$refs["form"].validate((valid) => {
        //     if (!valid) {
        //       this.$message.warning("请填写完整的信息");
        //       return;
        //     }
        //     if (!this.$refs["notice"].checked) {
        //       this.$message.warning("请阅读并同意《服务协议》");
        //       return;
        //     }
        //     this.stepInfo.active++;
        //   });
        //   break;
        case 0:
          // this.$refs["agent"].$refs["form"].validate((valid) => {
          //   if (!valid) {
          //     this.$message.warning("请填写完整的信息");
          //     return false;
          //   }
          // });
          // this.$refs["bank"].$refs["form"].validate((valid) => {
          //   if (!valid) {
          //     this.$message.warning("请填写完整的信息");
          //     return false;
          //   }
          // });
          if (!this.$refs["project"].$refs["parseFormViewDiv"]) {
            this.$message.error("错误，未读取到项目表单信息");
            return;
          }
          let newFormData = await this.$refs["project"].$refs[
            "parseFormViewDiv"
          ].getFormData();
          if (!newFormData) {
            this.$message.warning("请检查填写的表单信息");
            return;
          }
          // 验证新增的两个字段
          // this.$refs.otherProjectInfo.validate((valid) => {
          //   if (valid) {
          //     this.moreData.subdata = JSON.stringify(newFormData);
          //     this.stepInfo.active++;
          //   } else {
          //     return;
          //   }
          // });
          this.moreData.subdata = JSON.stringify(newFormData);
          this.stepInfo.active++;
          break;
        case 1:
          // 校验材料的必传
          for (let i = 0; i < this.materialData.length; i++) {
            const material = this.materialData[i];
            if (material.isneed == "1" && !material.tempFiles.length) {
              this.$message.warning("请上传必要的材料文件");
              return;
            }
          }
          this.stepInfo.active++;
          break;
        default:
          break;
      }
    },
    /**
     * @description: 提交申报
     */
    submit() {
      // console.log('policyData:', this.policyData)
      // console.log('moreData:', this.moreData)
      // console.log('materialData:', this.materialData)
      // console.log('agentData:', this.agentData)
      // console.log('enterpriseData:', this.enterpriseData)
      this.$confirm("确认信息填写无误", "信息确认", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(async () => {
          let params = {};
          this.$route.query.status;
          if (this.$route.query.status == "10") {
            // 暂存件提交
            params["baseinfo"] = {
              sxid: this.zancunsxid,
              bstype: "1", // 业务类型{'1':企业, '2':个人}
              deptid: this.policyData.deptCode, // 部门ID
              deptname: this.policyData.deptName, // 部门名称
              sxmc: this.policyData.name, // 事项名称(政策名称？)
              type: "1", // 业务来源,
              zcid:this.$route.query.zcid
            };
          } else if (this.projectId) {
            // 业务信息(新申报时，很多字段没有值，忽略掉)
            params.baseinfo = JSON.parse(JSON.stringify(this.projectData));
            // 修正数据
            params.baseinfo.bsnum = params.baseinfo.id;
            Reflect.deleteProperty(params.baseinfo, "id");
          } else {
            params.baseinfo = {
              bstype: "1", // 业务类型{'1':企业, '2':个人}
              deptid: this.policyData.deptCode, // 部门ID
              deptname: this.policyData.deptName, // 部门名称
              sxid: this.policyData.id, // 事项ID(政策ID？)
              sxmc: this.policyData.name, // 事项名称(政策名称？)
              type: "1", // 业务来源
            };
          }
          // 处理信息
          // params.dealInfo = {}
          // 业务表单信息
          params.formInfo = JSON.parse(JSON.stringify(this.moreData));
          let newFormData = await this.$refs["project"].$refs[
            "parseFormViewDiv"
          ].getFormData();
          if (this.isSpecialProject == "true") {
            this.newForm.otherFlag = "1";
            this.newForm.otherPorject = newFormData.xmmc;
          }
          params.formInfo.subdata = JSON.stringify(newFormData) || "";
          params.formInfo = [params.formInfo];
          // 附加信息
          // params.info = {}
          // 业务材料信息
          params.materials = JSON.parse(JSON.stringify(this.materialData));
          for (let i = 0; i < params.materials.length; i++) {
            const material = params.materials[i];
            material.files = material.tempFiles.map((cv) => {
              return {
                fileid: cv.fileid,
                filename: cv.filename,
                filepath: cv.filepath || cv.viewUrl || "",
                id: "",
                ly: cv.ly || material.ly || "",
                mid: cv.mid || material.id || "",
                status: "1",
              };
            });
          }
          // 申报对象信息
          params.operator = {};
          //     经办人信息
          params.operator.agent = JSON.parse(JSON.stringify(this.agentData));
          //     申报类型{'1':企业, '2':个人}
          params.operator.apptype = "1";
          //     企业信息
          params.operator.enterprise = JSON.parse(
            JSON.stringify(this.enterpriseData)
          );
          //     操作人用户(登录用户)信息
          params.operator.reporter = {
            operateaccount: this.userInfo.userName, // 用户账号
            operateid: this.userInfo.userId, // 用户ID
            optaddress: "", // 地址
            optbirthday: "", // 出生日期
            optcertificatename: "身份证", // 证件名称
            optcertificatenumber: "", // 证件号码
            optcertificatetype: "10001", // 证件类型
            optemail: "", // 电子邮箱
            optgender: this.userInfo.sex, // 性别
            optmobilephone: this.userInfo.userName, // 电话号码
            optname: this.userInfo.nickName, // 姓名
          };
          // 诉求工单信息
          // params.sqInfo = {}
          // 业务状态信息
          params.statusInfo = {
            start: true,
          };
          params.baseinfo["otherFlag"] = this.newForm.otherFlag;
          params.baseinfo["otherPorject"] = this.newForm.otherPorject;
          this.declarePolicy(params, () => {
            // this.stepInfo.active = this.stepInfo.list.length - 1;
            // this.backTimer = setInterval(() => {
            //     this.countdown -= 1
            //     if (this.countdown === 0) {
            //         clearInterval(this.backTimer)
            //         this.$router.replace({
            //             path: '/workplace/index',
            //         })
            //     }
            // }, 1000)
            this.goWorkplace();
            this.updateZancun();
          });
          // this.updateZancun();
          // this.$refs.bank.addUp();
        })
        .catch((e) => {
          e;
        });
    },
    // 修改暂存
    async updateTempSave() {
      let URL = "/dev-api/workplace/updateZancun";
      let Param = {
        bizZancunWorkplace: {},
      };
      for (let key in this.moreData) {
        Param.bizZancunWorkplace[key] = this.moreData[key];
      }
      for (let key in this.agentData) {
        Param.bizZancunWorkplace[key] = this.agentData[key];
      }
      for (let key in this.enterpriseData) {
        Param.bizZancunWorkplace[key] = this.enterpriseData[key];
      }
      let newFormData = await this.$refs["project"].$refs[
        "parseFormViewDiv"
      ].getFormData();
      if (this.isSpecialProject == "true") {
        this.newForm.otherFlag = "1";
        this.newForm.otherPorject = newFormData.xmmc;
      }
      Param.bizZancunWorkplace.subdata = JSON.stringify(newFormData);
      Param.bizZancunWorkplace["tyxydm"] = this.userInfo.tyshxydm;
      Param.bizZancunWorkplace.step = this.stepInfo.active;
      Param.bizZancunWorkplace["id"] = this.$route.query.pId;
      Param;
      let temp = JSON.parse(Param.bizZancunWorkplace["subdata"]);
      let temp2 = JSON.parse(Param.bizZancunWorkplace["formdata"]);
      console.log(temp, temp2);
      console.log(Param);
      // 新增的两个字段
      Param.bizZancunWorkplace["otherFlag"] = this.newForm.otherFlag;
      Param.bizZancunWorkplace["otherPorject"] = this.newForm.otherPorject;
      this.newFormCache = this.newForm;
      this.$httpApi
        .post(URL, Param)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("暂存成功");
          }
        })
        .catch((e) => {});
    },
    // 暂存验证表单
    async tempSave() {
      let formValidated = await this.formCheck();
      if (formValidated) {
        this.tempSaveAction();
      } else {
        return;
      }
    },
    // 暂存
    async tempSaveAction() {
      this.tempSaveFlag = true;
      let params = {};
      this.$route.query.status;
      if (this.$route.query.status == "10") {
        // 暂存件提交
        params["baseinfo"] = {
          sxid: this.zancunsxid,
          bstype: "1", // 业务类型{'1':企业, '2':个人}
          deptid: this.policyData.deptCode, // 部门ID
          deptname: this.policyData.deptName, // 部门名称
          sxmc: this.policyData.name, // 事项名称(政策名称？)
          type: "1", // 业务来源
        };
        this.updateTempSave();
        return;
      } else if (this.projectId) {
        // 业务信息(新申报时，很多字段没有值，忽略掉)
        params.baseinfo = JSON.parse(JSON.stringify(this.projectData));
        // 修正数据
        params.baseinfo.bsnum = params.baseinfo.id;
        Reflect.deleteProperty(params.baseinfo, "id");
      } else {
        params.baseinfo = {
          bstype: "1", // 业务类型{'1':企业, '2':个人}
          deptid: this.policyData.deptCode, // 部门ID
          deptname: this.policyData.deptName, // 部门名称
          sxid: this.policyData.id, // 事项ID(政策ID？)
          sxmc: this.policyData.name, // 事项名称(政策名称？)
          type: "1", // 业务来源
        };
      }
      // if (this.tempSaveFlag && this.stepInfo.active == 0) {
      //   this.moreData = {};
      // } else {
      // 处理信息
      // params.dealInfo = {}
      // 业务表单信息
      params.formInfo = JSON.parse(JSON.stringify(this.moreData));
      let newFormData = await this.$refs["project"].$refs[
        "parseFormViewDiv"
      ].getFormData();
      if (this.isSpecialProject == "true") {
        this.newForm.otherFlag = "1";
        this.newForm.otherPorject = newFormData.xmmc;
      }
      params.formInfo.subdata = JSON.stringify(newFormData) || "";
      params.formInfo = [params.formInfo];
      // 附加信息
      // params.info = {}
      // 业务材料信息
      params.materials = JSON.parse(JSON.stringify(this.materialData));
      for (let i = 0; i < params.materials.length; i++) {
        const material = params.materials[i];
        material.files = material.tempFiles.map((cv) => {
          return {
            fileid: cv.fileid,
            filename: cv.filename,
            filepath: cv.filepath || cv.viewUrl || "",
            id: "",
            ly: cv.ly || material.ly || "",
            mid: cv.mid || material.id || "",
            status: "1",
          };
        });
      }
      // 申报对象信息
      params.operator = {};
      //     经办人信息
      params.operator.agent = JSON.parse(JSON.stringify(this.agentData));
      //     申报类型{'1':企业, '2':个人}
      params.operator.apptype = "1";
      //     企业信息
      params.operator.enterprise = JSON.parse(
        JSON.stringify(this.enterpriseData)
      );
      //     操作人用户(登录用户)信息
      params.operator.reporter = {
        operateaccount: this.userInfo.userName, // 用户账号
        operateid: this.userInfo.userId, // 用户ID
        optaddress: "", // 地址
        optbirthday: "", // 出生日期
        optcertificatename: "身份证", // 证件名称
        optcertificatenumber: "", // 证件号码
        optcertificatetype: "10001", // 证件类型
        optemail: "", // 电子邮箱
        optgender: this.userInfo.sex, // 性别
        optmobilephone: this.userInfo.userName, // 电话号码
        optname: this.userInfo.nickName, // 姓名
      };
      // 诉求工单信息
      // params.sqInfo = {}
      // 业务状态信息
      params.statusInfo = {
        start: true,
      };
      // }
      // 信息填报
      // params.xxtbInfos = {}
      if (this.tempSaveFlag) {
        let url = "/dev-api/workplace/addZancun";
        let newParams = {
          bizZancunWorkplace: {},
          baseinfo: params.baseinfo,
        };
        for (let key in this.moreData) {
          newParams.bizZancunWorkplace[key] = this.moreData[key];
        }
        for (let key in this.agentData) {
          newParams.bizZancunWorkplace[key] = this.agentData[key];
        }
        for (let key in this.enterpriseData) {
          newParams.bizZancunWorkplace[key] = this.enterpriseData[key];
        }
        newParams.bizZancunWorkplace["tyxydm"] = this.userInfo.tyshxydm;
        newParams.bizZancunWorkplace.step = this.stepInfo.active;
        // if (this.stepInfo.active != 0) {
        // subdata数据缺失，再赋值一次
        let newFormData2 = await this.$refs["project"].$refs[
          "parseFormViewDiv"
        ].getFormData();
        newParams.bizZancunWorkplace.subdata =
          JSON.stringify(newFormData2) || "";
        // }
        // newParams;
        // 新增的两个字段
        newParams.bizZancunWorkplace["otherFlag"] = this.newForm.otherFlag;
        newParams.bizZancunWorkplace["otherPorject"] =
          this.newForm.otherPorject;
        this.newFormCache = this.newForm;
        this.$httpApi
          .post(url, newParams)
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("暂存成功！");
            } else {
              this.$message.error("暂存失败！");
            }
          })
          .catch((e) => {
            console.log("temmSave Err", e);
          });
      } else {
        this.declarePolicy(params, () => {
          this.stepInfo.active = this.stepInfo.list.length - 1;
          // this.backTimer = setInterval(() => {
          //     this.countdown -= 1
          //     if (this.countdown === 0) {
          //         clearInterval(this.backTimer)
          //         this.$router.replace({
          //             path: '/workplace/index',
          //         })
          //     }
          // }, 1000)
          this.updateZancun();
        });
      }
    },
    /**
     * @description: 前往工作台
     */
    goWorkplace() {
      this.$router.replace({
        path: "/workplace/index",
      });
    },
    /**
     * @description: 查询政策信息
     * @param {String} id 政策ID
     */
    getPolicyData(id) {
      let url = `/dev-api/basicinfo/baseitem/${id}`;
      let params = {};
      this.$httpApi
        .get(url, params)
        .then((res) => {
          // console.log("getPolicyData:", res);
          if (res.code == 200) {
            this.policyData = res.data.itemList[0];
            // 通过 表单ID 查询项目信息
            this.getFormData(this.policyData.formid);
            // 取出 材料列表
            this.materialData = this.policyData.clxxList.map((cv) => {
              return {
                bsnum: cv.bsnum || "", // 业务流水号
                certid: cv.certid || "", // 证照ID
                clbm: cv.clbm || "", // 材料编码
                clid: cv.clid || "", // 材料ID
                clmc: cv.clname || "", // 材料名称
                copynum: cv.copynum || "", // 复印件份数
                // 附件
                files: [
                  // {
                  //     fileid: '', // 文件ID
                  //     filename: '', // 文件名称
                  //     filepath: '', // 文件路径
                  //     id: '',
                  //     ly: '', // 来源
                  //     mid: '', // 材料ID
                  //     status: '1', // 状态{'1':生效}
                  // },
                ],
                tempFiles: [], // 临时附件，页面用
                id: "",
                isneed: cv.sfby || "0", // 是否必要
                ly: cv.ly || "", // 来源
                orinum: cv.orinum || "", // 原件份数
                sfrq: cv.sfrq || "1", // 是否容缺
                shyj: cv.shyj || "", // 审批意见
                shzt: cv.shzt || "", // 审批状态{'0':未审核}
                status: cv.status || "1", // 状态{'1':提交}
                submittype: cv.submittype || "", // 提交类型
                sxid: cv.sxid || "", // 事项ID
                yburl: cv.yburl || "", // 是个啥？
                tbxz: cv.tbxz || "", // 填报须知
                ybid: cv.ybid || "", // 样表文件ID
                cltitle:cv.cltitle || "", // 材料分类标题
                cltitleName:cv.cltitlename || "", // 材料分类标题名称
              };
            });
          }
        })
        .catch((err) => {
          console.log("getPolicyData error:", err);
        });
    },
    /**
     * @description 查询企业信息
     * @param {String} code 统一社会信用代码
     */
    getCompanyData() {
      let url = "/dev-api/enterprise/baseInfo/list";
      let params = {
        pageSize: 10, // 页大小
        pageNum: 1, // 页码
        shxydm: this.userInfo.tyshxydm,
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          this.companyData = res.rows[0] || {};
          // 新申报时填充初始数据
          if (!this.projectId) {
            // 企业信息
            this.enterpriseData.unitaddress = this.companyData.zcdz;
            this.enterpriseData.legalperson = this.companyData.frdb;
          }
        })
        .catch((err) => {
          console.log("getCompanyData error:", err);
        });
    },
    // 获取联系人
    getContact() {
      let url = "/dev-api-jh/enterprise/contact/webId/" + this.userInfo.tyshxydm;
      this.$httpApi
        .get(url)
        .then((res) => {
          // 解决联系人读取错误
          this.agentData.agentmobilephone = res.data.phone;
          this.agentData.agentname = res.data.name;
          this.agentData.zw = res.data.zw;
          this.agentData.lxrlx = res.data.lxrlx;
          this.agentData.qq = res.data.qq;
          this.agentData.gddh = res.data.gddh;
          this.agentData.wxh = res.data.wxh;
          this.agentData.ssdq = res.data.ssdq;
          this.agentData.agentemail = res.data.email;
          this.agentData.chz = res.data.chz;
        })
        .catch();
    },
    /**
     * @description 查询登录用户的联系人信息
     * @param {String} tyshxydm 统一社会信用代码
     */
    getUserContactData(tyshxydm) {
      let url = "/dev-api-jh/enterprise/contact/list";
      let params = {
        webEid: tyshxydm,
        pageNum: 0,
        pageSize: 10,
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          // console.log('getUserContactData:', res)
          if (res.code == 200 && !!res.rows.length) {
            this.agentData.agentcertificatenumber = res.rows[0].zjh;
          }
        })
        .catch((err) => {
          console.log("getUserContactData error:", err);
        });
    },
    /**
     * @description 查询登录用户的办公地址信息
     * @param {String} tyshxydm 统一社会信用代码
     */
    getUserAddressData(tyshxydm) {
      let url = "/dev-api/enterprise/address/list";
      let params = {
        webEid: tyshxydm,
        pageNum: 0,
        pageSize: 10,
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          // console.log('getUserContactData:', res)
          if (res.code == 200 && !!res.rows.length) {
            this.agentData.agentaddress = res.rows[0].address;
          }
        })
        .catch((err) => {
          console.log("getUserContactData error:", err);
        });
    },
    /**
     * @description 查询动态表单信息
     * @param {String} formId 表单ID
     */
    getFormData(formId) {
      let url = `/dev-api/basicinfomanage/info/${formId}`;
      let params = {};
      this.$httpApi
        .get(url, params)
        .then((res) => {
          if (res.code == 200) {
            this.moreData.formdata = res.data.formContent;
            this.moreData.formid = res.data.id;
          }
        })
        .catch((err) => {
          console.log("getFormData error:", err);
        });
    },
    /**
     * @description 查询政策项目信息(暂存、退回时)
     * @param {String} id 项目ID
     */
    getProjectData(id) {
      let url = `/dev-api/userBusiness/getBusinessInfo?id=${id}`;
      let params = {};
      const loading = this.$loading({});
      this.$httpApi
        .post(url, params)
        .then((res) => {
          console.log("getProjectData:", res);
          loading.close();
          if (res.code == 200) {
            this.projectData = res.data.masterinfo;
            this.newForm.otherFlag = Number(res.data.masterinfo.otherFlag);
            this.newForm.otherPorject = res.data.masterinfo.otherPorject;
            let baseData = JSON.parse(res.data.basicInfo.subdata);
            this.enterpriseData = baseData.enterprise;
            this.agentData = baseData.agent;
            this.moreData = res.data.formData;
            this.materialData = res.data.material.map((cv) => {
              let obj = JSON.parse(JSON.stringify(cv));
              obj.tempFiles = obj.masterinfoList.map((file) => {
                return {
                  ...file,
                  name: file.filename,
                  url: `${this.$httpApi.baseUrl}/dev-api/fileManageApi/common/fileView/${file.fileid}`,
                };
              });
              // Reflect.deleteProperty(obj, masterinfoList)
              return obj;
            });
          }
        })
        .catch((err) => {
          console.log("getProjectData error:", err);
          loading.close();
        });
    },
    /**
     * @description: 申报政策项目
     * @param {Object} params 请求参数
     * @param {Function} cb 回调函数
     */
    declarePolicy(params, cb) {
      const loading = this.$loading({});
      let url = "/dev-api/business/handle/report";
      this.$httpApi
        .post(url, params)
        .then((res) => {
          // console.log('declarePolicy:', res)
          loading.close();
          if (res.code == 200) {
            this.newProjectId = res.msg;
            this.$message.success("提交成功");
            cb();
          } else {
            this.$message.error("操作错误，请检查");
          }
        })
        .catch((err) => {
          console.log("declarePolicy error:", err);
          loading.close();
          this.$message.error("错误：", err);
        });
    },
  },
  provide() {
    return {
      policyData: () => this.policyData,
      policyId: () => this.cpu_policyId,
      companyData: () => this.companyData,
      enterpriseData: () => this.enterpriseData,
    };
  },
  created() {
    this.getEnterpriseAttribute()
    if (this.$route.query.pId) {
      this.showTempSave = false;
      this.projectId = this.$route.query.pId;
      if (this.$route.query.status == "10") {
        this.stepInfo.active = Number(this.$route.query.step);
        this.getZancunData(this.projectId);
      } else {
        this.getProjectData(this.projectId);
      }
    } else {
      this.showTempSave = true;
      this.getPolicyData(this.$route.query.policyId);
    }
    this.isSpecialProject = sessionStorage.getItem("isSpecialProject");
    if (this.isSpecialProject == "true") {
      this.rules.otherFlag[0].required = false;
      this.rules.otherPorject[0].required = false;
    }
    this.templateInfo = JSON.parse(this.$route.query.templateInfo);

    // let code = this.userInfo.tyshxydm;
    // 获取企业信息
    this.getCompanyData();
    // 获取联系人信息
    this.getContact();
  },
  mounted(){
    if (this.$route.query.pId) {
      this.showTempSave = false;
      this.projectId = this.$route.query.pId;
      if (this.$route.query.status == "10") {
        this.stepInfo.active = Number(this.$route.query.step);
        this.getZancunData(this.projectId);
      } else {
        this.getProjectData(this.projectId);
      }
    } else {
      this.showTempSave = true;
      this.getPolicyData(this.$route.query.policyId);
    }
  },
  beforeDestroy() {
    if (this.backTimer) {
      clearInterval(this.backTimer);
    }
  },
  watch: {
    userInfo: {
      immediate: true,
      handler: function (newVal) {
        // console.log('userInfo:', newVal)
        if (!newVal) return;
        // 携带pId(项目ID)过来的，不需要获取初始值
        // 直接查询项目信息赋值
        if (!this.$route.query.pId) {
          // 补充 企业信息&&经办人信息
          console.log("--------------", newVal);
          this.enterpriseData.unitname = newVal.enterpriseInfo?.enterprise;
          this.enterpriseData.unitcardcode = newVal.tyshxydm;
          this.agentData.agentname = newVal.nickName;
          this.agentData.agentmobilephone = newVal.phonenumber;
          this.agentData.agentgender = newVal.sex;
          // 经办人用户ID
          // 原来取的id，目前扫码登录id是空的，尝试取userId
          this.agentData.agentuserid = newVal.userId;
          // 通过 统一社会信用代码 查询 登录用户的联系人信息/登录用户的办公地址信息
          this.getUserContactData(newVal.tyshxydm);
          this.getUserAddressData(newVal.tyshxydm);
        }
        // 通过 统一社会信用代码 查询 企业信息
        this.getCompanyData(newVal.tyshxydm);
      },
    },
    "stepInfo.active"(newVal, oldVal) {
      if (oldVal < newVal) {
        // 切换页面内容时，滚动到顶部，避免用户体验不好
        let animate = function () {
          let scrollTop = document.documentElement.scrollTop - 50;
          document.documentElement.scrollTop = scrollTop < 0 ? 0 : scrollTop;
          if (0 < document.documentElement.scrollTop) {
            window.requestAnimationFrame(animate);
          }
        };
        window.requestAnimationFrame(animate);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
.banner-size {
  height: 130px;
  background: url("~@/assets/deyangImg/zcjsqBanber.png") no-repeat 100% / cover;
  .banner-inner {
    display: -webkit-flex;
    display: flex;
    align-items: center;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .banner-fMin {
      margin-left: 15px;
    }
  }
}
.pd-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  display: flex;
  justify-content: center;
}
.btn {
  margin-left: 20px;
  border-radius: 20px;
  background-color: #3989c4 !important;
}
::v-deep .pd-steps {
  margin: 20px auto;
}
.container {
  padding-right: 70px;
  padding-left: 70px;
  margin: 0 auto;
}
::v-deep .personal {
  overflow: hidden;
}
::v-deep .notice {
  margin-top: 20px;
}
.step-finishInfo {
  ::v-deep .el-input__inner,
  ::v-deep .el-input-group__prepend,
  ::v-deep .el-textarea__inner {
    min-height: 36px;
  }
}
.step-confirm {
  ::v-deep .el-input__inner,
  ::v-deep .el-input-group__prepend,
  ::v-deep .el-textarea__inner {
    // border: none;
    color: #333333;
    min-height: 36px;
    // background: none;
  }
  ::v-deep .el-input-group__prepend {
    padding-left: 15px;
  }
  .confirm-project {
    ::v-deep .el-input-number__decrease,
    ::v-deep .el-input-number__increase {
      display: none;
    }
    ::v-deep .el-textarea__inner {
      resize: none;
    }
  }
}
.step-confirm2 {
  ::v-deep .el-input.is-disabled .el-input__inner {
    border: none !important;
  }
}
.step-success {
  text-align: center;
  .step-success-icon {
    position: relative;
    width: calc(8rem + 20px);
    height: calc(8rem + 20px);
    padding: 10px;
    margin: 60px auto 30px auto;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: rgba($col-green, 0.2);
      z-index: 0;
    }
    .el-icon-success {
      position: relative;
      font-size: 8rem;
      color: $col-green;
      z-index: 1;
    }
  }
  .step-success-text {
    max-width: 640px;
    margin: 0 auto 60px auto;
    span {
      font-weight: bold;
      color: #333333;
    }
  }
}
.footBtns {
  margin-top: 20px;
  text-align: center;
  ::v-deep .el-button:not([disabled]).el-button--primary:hover,
  ::v-deep .el-button:not([disabled]).el-button--primary {
    border-color: #3989c4;
    background-color: #3989c4;
  }
}
</style>
