<!--
 * @Author: LXG
 * @Date: 2021-04-28
 * @Editors: LXG
 * @LastEditTime: 2021-04-28
 * @Description: 申报须知
-->
<template>
    <div class="notice">
        <el-checkbox v-model="checked">
            <span>我已阅读并同意遵守</span>
            <a @click.prevent="showDialog">《服务协议》</a>
        </el-checkbox>
        <el-dialog
            class="notice-dialog"
            :visible.sync="show"
            title="服务协议"
        >
            <div
                class="content"
                v-html="content"
            ></div>
            <template v-slot:footer>
                <el-button
                    type="primary"
                    @click="changeAgree(true)"
                >同意</el-button>
                <el-button @click="changeAgree(false)">不同意</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'Notice',
    props: {
    },
    data() {
        return {
            checked: false,
            show: false,
            content: `
            <p style="font-weight:bold;">申办协议：</p>
            <p style="text-indent:28px;">申请人对所提请行政审批事项的法律、法规和政策依据， 准予行政审批的条件、标准和技术要求， 需要提交材料的名称、方式和期限等内容，已全部知悉。 申请人严格按要求提供相关申请材料，并按指引上传。</p>
            <p style="font-weight:bold;">申请人郑重承诺：</p>
            <p style="text-indent:28px;">上传的申请材料、证件扫描件与书面申请材料、证件原件一致， 均客观真实、合法有效，不存在隐瞒、伪造、篡改等任何违法情形。 申请人愿意按审批流程要求现场提交书面申请材料供校验核对， 如上传材料与现场提交书面材料不一致或存在隐瞒、伪造、篡改等违法情形， 愿意承担一切法律后果，包括但不限于终止审批流程、撤销审批结果以及相应的行政处罚或刑事责任。</p>
            `,
        }
    },
    methods: {
        showDialog() {
            this.show = true
        },
        changeAgree(status) {
            this.checked = status

            this.$nextTick(() => {
                this.show = false
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.notice-dialog {
}
::v-deep .content {
    line-height: 28px;
    font-size: 14px;
}
::v-deep .el-dialog__footer {
    text-align: left;

    .el-button {
        width: 100px;
    }
}
</style>