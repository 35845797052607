<!--
 * @Description: 政策项目申报 - 材料信息
 * @Author: LXG
 * @Date: 2021-04-28
 * @Editors: LXG
 * @LastEditTime: 2021-06-24
-->
<template>
  <div class="material">
    <div v-if="!disabled" class="m-header">
      <p>温馨提示：</p>
      <div>
        <p>附件上传格式为 PDF，单文件大小不超过 50M。</p>
      </div>
    </div>
    <el-table :data="cpu_value" border stripe default-expand-all>
      <el-table-column type="expand">
        <template slot-scope="scope">
          <FormFileUploader
            v-if="scope.row.tempFiles"
            :ref="'formFileUploader' + scope.$index"
            v-model="scope.row.tempFiles"
            :disabled="handlePass(scope.row.shzt).flag"
            areaSize="small"
            :maxSize="50"
            upload
            :suffix="limitType"
            :afterSelect="(filer, cb) => afterSelect(scope.row, filer, cb)"
          ></FormFileUploader>
        </template>
      </el-table-column>
      <el-table-column
        label="序号"
        type="index"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column label="材料名称" header-align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.isneed == 1" class="required-star">*</span>
          <span style="font-weight: bold">{{ scope.row.clmc || "" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="填报须知" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.tbxz || "暂无" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="查看样表" width="100px" align="center">
        <template slot-scope="scope">
          <a
            v-if="scope.row.ybid"
            @click="downloadTemplateFile(scope.row, scope.$index)"
            >下载</a
          >
          <span v-else>暂无</span>
        </template>
      </el-table-column>
      <el-table-column label="是否通过" width="100px" align="center">
        <template slot-scope="scope">
          <span
            :style="
              scope.row.shzt == '1'
                ? 'font-weight: bold;color:#13CE66'
                : 'font-weight: bold;color:red'
            "
            >{{ handlePass(scope.row.shzt).advice }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="审核意见" header-align="center">
        <template slot-scope="scope">
          <span
            v-show="!handlePass(scope.row.shzt).flag"
            :style="
              scope.row.shzt == '1'
                ? 'font-weight: bold;color:#13CE66'
                : 'font-weight: bold;color:red'
            "
            >{{ scope.row.shyj || "" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        v-if="!disabled && scope.row.shzt !== '1'"
        label="操作"
        width="160px"
        align="center"
      >
        <template slot-scope="scope">
          <!-- <el-button
                        type="primary"
                        size="mini"
                        @click="selectLocalFile(scope.row,scope.$index)"
                    >本地上传</el-button> -->
          <el-button
            :disabled="handlePass(scope.row.shzt).flag"
            size="mini"
            @click="selectHistoryFile(scope.row, scope.$index)"
            >选择历史材料文件</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      custom-class="material-dialog"
      :visible.sync="dialogVisible"
      width="1024px"
      title="历史材料文件"
      @open="openDialog"
      @closed="closedDialog"
    >
      <div class="dialog-transfer">
        <div class="tf-left">
          <el-checkbox
            class="tf-checkall"
            v-model="transferInfo.leftCheckAll"
            :indeterminate="transferInfo.leftCheckPart"
            @change="changeLeftCheckAll"
          >
            <span>可选文件列表</span>
            <span class="tf-checkall-num">
              {{ transferInfo.leftValue.length }}/{{ cpu_leftData.length }}
            </span>
          </el-checkbox>
          <el-checkbox-group
            v-model="transferInfo.leftValue"
            @change="changeLeftValue"
          >
            <el-checkbox
              v-for="(i, index) in cpu_leftData"
              :key="index"
              :label="i.fileid"
            >
              {{ i.filename }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="tf-center">
          <el-button
            type="primary"
            :disabled="!transferInfo.rightValue.length"
            @click="delValue"
          >
            <span><i class="el-icon-arrow-left"></i></span>
          </el-button>
          <el-button
            type="primary"
            :disabled="!transferInfo.leftValue.length"
            @click="addValue"
          >
            <span><i class="el-icon-arrow-right"></i></span>
          </el-button>
        </div>
        <div class="tf-right">
          <el-checkbox
            class="tf-checkall"
            v-model="transferInfo.rightCheckAll"
            :indeterminate="transferInfo.rightCheckPart"
            @change="changeRightCheckAll"
          >
            <span>已选文件列表</span>
            <span class="tf-checkall-num">
              {{ transferInfo.rightValue.length }}/{{
                transferInfo.rightData.length
              }}
            </span>
          </el-checkbox>
          <div class="tf-right-selected">
            <p
              v-for="(item, index) in cpu_currentMaterial.tempFiles || []"
              :key="index"
            >
              {{ item.filename }}
            </p>
          </div>
          <el-checkbox-group
            v-model="transferInfo.rightValue"
            @change="changeRightValue"
          >
            <el-checkbox
              v-for="(i, index) in transferInfo.rightData"
              :key="index"
              :label="i.fileid"
              :disabled="i.disabled"
            >
              {{ i.filename }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <template v-slot:footer>
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirm">确认选择</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { FormTextarea } from '@/components/form/index.js'
import { FormFileUploader } from "@/components/form/index.js";

export default {
  name: "Material",
  components: {
    FormFileUploader,
  },
  props: {
    value: [],
    disabled: {
      type: Boolean,
      default: false,
    },
    // materialData:{},
  },
  data() {
    return {
      dialogVisible: false,
      currentIndex: 0, // 当前操作的材料下标
      searchInfo: {
        data: {
          filename: "", // 文件名
        },
      },
      materialData: [
        {
          bsnum: "",
          certid: "",
          clbm: "TY-FDDBRSFZMS",
          clid: "3e8acc85193f4ba6a850eecb96e4ce77",
          clmc: "法定代表人身份证明书",
          copynum: "1",
          files: [],
          tempFiles: [
            {
              id: "20220806205145000300",
              loginId: "141",
              fileid: "fab6eeb9-c75c-48e1-b8b6-d2273597a3fd",
              filename: "需求文档 - 企业端.pdf",
              viewUrl:
                "http://47.119.134.203:9700/balocal-api/common/fileView/fab6eeb9-c75c-48e1-b8b6-d2273597a3fd",
              createTime: "2022-08-06 20:51:46",
              createBy: null,
              clid: "3e8acc85193f4ba6a850eecb96e4ce77",
              clbh: "TY-FDDBRSFZMS",
              name: "需求文档 - 企业端.pdf",
              url: "http://47.119.134.203:9700/balocal-api/dev-api/fileManageApi/common/fileView/fab6eeb9-c75c-48e1-b8b6-d2273597a3fd",
              suffix: "pdf",
            },
          ],
          id: "",
          isneed: "0",
          ly: "",
          orinum: "1",
          sfrq: "0",
          shyj: "",
          shzt: "",
          status: "1",
          submittype: "",
          sxid: "5d2ee56c7f9d419fb2fef5900f578762",
          yburl: "",
          tbxz: "原件，盖公章",
          ybid: "28b7d688-dd73-4d1c-a2a6-5ef6da3a4c3d,",
          cltitle: "0",
          cltitleName: "申报材料",
        },
        {
          bsnum: "",
          certid: "",
          clbm: "BA2021001007-002",
          clid: "27bd52807b084d4c8c21e1d656e5b88a",
          clmc: "在我区从事双跨平台业务的相关证明文件",
          copynum: "0",
          files: [],
          tempFiles: [],
          id: "",
          isneed: "0",
          ly: "",
          orinum: "1",
          sfrq: "0",
          shyj: "",
          shzt: "",
          status: "1",
          submittype: "",
          sxid: "5d2ee56c7f9d419fb2fef5900f578762",
          yburl: "",
          tbxz: "加盖公章",
          ybid: "442fb3d7-d662-4bcc-b9b4-73a87824f845,",
          cltitle: "1",
          cltitleName: "其他附件",
        },
      ],
      transferInfo: {
        leftCheckAll: false, // 左侧全选
        leftCheckPart: false, // 左侧选中部分
        leftValue: [], // 左侧选中值
        rightCheckAll: false, // 右侧全选
        rightCheckPart: false, // 右侧选中部分
        rightValue: [], // 右侧选中值
        rightData: [], // 右侧值
      },
      historyData: [], // 历史材料文件列表
      transferData: [], // 右侧已选文件列表
      inited: false, // 是否已初始化
      limitType: ["pdf"], //上传文件限制为pdf类型
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
    }),
    cpu_value: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    /**
     * @description: 当前操作的材料
     */
    cpu_currentMaterial() {
      return this.cpu_value[this.currentIndex] || {};
    },
    cpu_leftData() {
      return this.historyData.filter((cv) => {
        return !this.transferInfo.rightData.some(
          (file) => file.fileid === cv.fileid
        );
      });
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 处理回显通不通过
    handlePass(val) {
      let label = "";
      let flag = false;
      switch (val) {
        case "1":
          label = "通过";
          flag = true;
          break;
        case "0":
          label = "不通过";
          flag = false;
          break;
        case "2":
          label = "不通过";
          flag = false;
          break;
        default:
          label = "";
          flag = false;
          break;
      }
      let obj = {
        advice: label,
        flag: flag,
      };
      return obj;
    },
    /**
     * @description 下载模板(样表)文件
     * @param {Object} item 材料
     * @param {Number} index 材料下标
     */
    downloadTemplateFile(item, index) {
      let download = document.createElement("a");
      download.setAttribute("target", "_blank");
      download.setAttribute("download", true);
      download.href = `${this.$httpApi.baseUrl}/dev-api/fileManageApi/common/fileView/${item.ybid}`;
      download.click();
    },
    /**
     * @description: 本地上传
     * @param {Object} item 当前材料
     * @param {Number} index 当前材料下表
     */
    selectLocalFile(item, index) {
      // console.log('selectLocalFile:', item, index)
      this.$refs["formFileUploader" + index].clickUpload();
    },
    /**
     * @description 选择文件后的钩子
     * @param {Object} material 材料
     * @param {Filer|Object} filer 文件信息
     * @param {Function} cb 回调函数
     */
    afterSelect(material, filer, cb) {
      // console.log('afterSelect:', material, filer, cb)
      this.$httpApi
        .uploadFile(filer.file)
        .then((res) => {
          // console.log('uploadFile:', res)
          if (res.code == 200) {
            filer.fileid = res.fileId;
            filer.filename = res.fileName;
            filer.filepath = res.url;
            filer.id = "";
            filer.ly = material.ly;
            filer.mid = material.id;
            filer.status = 1;
            filer.viewUrl = res.url;
            filer.url = `${this.$httpApi.baseUrl}/dev-api/fileManageApi/common/fileView/${res.fileId}`;
            cb(true);

            this.saveUploadRecord(material.clid, material.clbm, filer);
          } else {
            this.$message.error("上传错误，请联系管理员");
            cb(false);
          }
        })
        .catch((err) => {
          console.log("uploadFile error:", err);
          cb(false);
        });
    },
    /**
     * @description: 选择历史材料文件
     * @param {Object} item 当前材料
     * @param {Number} index 当前材料下表
     */
    selectHistoryFile(item, index) {
      // console.log('selectHistoryFile:', item, index)
      this.currentIndex = index;
      this.transferData = item.tempFiles.map((cv) => {
        return {
          ...cv,
          disabled: true,
        };
      });
      this.$nextTick(() => {
        this.dialogVisible = true;
      });
    },
    openDialog() {
      this.searchInfo.data.filename = "";
      this.historyData = [];
      this.getHistoryFileData();
    },
    closedDialog() {
      this.transferInfo = {
        leftCheckAll: false,
        leftCheckPart: false,
        leftValue: [],
        rightCheckAll: false,
        rightCheckPart: false,
        rightValue: [],
        rightData: [],
      };
    },
    /**
     * @description: 仿transfer 修改左侧全选
     * @param {Boolean} val 值
     */
    changeLeftCheckAll(val) {
      if (!this.cpu_leftData.length) {
        this.$set(this.transferInfo, "leftCheckAll", false);
        return;
      }
      if (val) {
        this.transferInfo.leftValue = this.cpu_leftData.map((cv) => cv.fileid);
      } else {
        this.transferInfo.leftValue = [];
      }
      this.transferInfo.leftCheckPart = false;
    },
    /**
     * @description: 仿transfer 修改右侧全选
     * @param {Boolean} val 值
     */
    changeRightCheckAll(val) {
      if (!this.transferInfo.rightData.length) {
        this.$set(this.transferInfo, "rightCheckAll", false);
        return;
      }
      if (val) {
        this.transferInfo.rightValue = this.transferInfo.rightData.map(
          (cv) => cv.fileid
        );
      } else {
        this.transferInfo.rightValue = [];
      }
      this.transferInfo.rightCheckPart = false;
    },
    /**
     * @description: 仿transfer 修改左侧选中值
     * @param {Array} val 左侧选中值
     */
    changeLeftValue(val) {
      if (!val.length) {
        this.transferInfo.leftCheckAll = false;
        this.transferInfo.leftCheckPart = false;
      } else if (val.length == this.cpu_leftData.length) {
        this.transferInfo.leftCheckAll = true;
        this.transferInfo.leftCheckPart = false;
      } else {
        this.transferInfo.leftCheckAll = false;
        this.transferInfo.leftCheckPart = true;
      }
    },
    /**
     * @description: 仿transfer 修改右侧选中值
     * @param {Array} val 右侧选中值
     */
    changeRightValue(val) {
      if (!val.length) {
        this.transferInfo.rightCheckAll = false;
        this.transferInfo.rightCheckPart = false;
      } else if (val.length == this.transferData.length) {
        this.transferInfo.rightCheckAll = true;
        this.transferInfo.rightCheckPart = false;
      } else {
        this.transferInfo.rightCheckAll = false;
        this.transferInfo.rightCheckPart = true;
      }
    },
    /**
     * @description: 仿transfer 添加选中值
     */
    addValue() {
      let selectData = this.transferInfo.leftValue.map((cv) =>
        this.cpu_leftData.find((file) => file.fileid === cv)
      );
      this.transferInfo.rightData.push(...selectData);

      this.transferInfo.leftCheckAll = false;
      this.transferInfo.leftCheckPart = false;
      this.transferInfo.leftValue = [];
    },
    /**
     * @description: 仿transfer 删除选中值
     */
    delValue() {
      this.transferInfo.rightData = this.transferInfo.rightData.filter((cv) => {
        return !this.transferInfo.rightValue.includes(cv.fileid);
      });

      this.transferInfo.rightCheckAll = false;
      this.transferInfo.rightCheckPart = false;
      this.transferInfo.rightValue = [];
    },
    /**
     * @description: 确认选择
     */
    confirm() {
      this.cpu_currentMaterial.tempFiles.push(...this.transferInfo.rightData);

      this.$nextTick(() => {
        this.dialogVisible = false;
      });
    },
    /**
     * @description 保存上传记录
     * @param {String} clid 材料ID
     * @param {String} clbm 材料编码
     * @param {Filer} filer 文件信息
     */
    saveUploadRecord(clid, clbm, filer) {
      let url = "/dev-api/fileRecord/saveRecords";
      let params = [
        {
          clid,
          clbm,
          fileid: filer.fileid,
          filename: filer.filename,
          loginId: this.userInfo.userId,
          createBy: this.userInfo.name,
          viewUrl: filer.viewUrl,
        },
      ];
      this.$httpApi
        .post(url, params)
        .then((res) => {
          // console.log('saveUploadRecord:', res)
        })
        .catch((err) => {
          console.log("saveUploadRecord error:", err);
        });
    },
    /**
     * @description: 查询用户历史上传文件
     * @param {*}
     * @return {*}
     */
    getHistoryFileData() {
      let url = `/dev-api/fileRecord/list/${this.userInfo.userId}`;
      let params = {
        pageNum: 1,
        pageSize: 20,
        filename: this.searchInfo.data.filename,
        clbm: this.cpu_value[this.currentIndex].clbm, // 待支持
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          // console.log('getHistoryFileData:', res)
          if (res.code == 200) {
            // 转换一下组件用的字段，组件为统一标准
            // this.historyData = res.rows
            this.historyData = res.rows.map((cv) => {
              return {
                ...cv,
                name: cv.filename,
                url: `${this.$httpApi.baseUrl}/dev-api/fileManageApi/common/fileView/${cv.fileid}`,
              };
            });
          }
        })
        .catch((err) => {
          console.log("getHistoryFileData error:", err);
        });
    },
  },
  watch: {
    value(newVal) {
      /**
       * 20210624
       * 第一次初始化时，取出历史材料
       * 遍历材料列表，对应材料编码，初始填充最新的一个
       *
       * 暂存/退回 的项目，重新编辑时，无需作填充
       */
      if (this.$route.query.pId) {
        this.inited = true;
        return;
      }
      if (!this.inited) {
        let url = `/dev-api/fileRecord/list/${this.userInfo.userId}`;
        let params = {
          pageNum: 1,
          pageSize: 20,
          filename: "",
        };
        this.$httpApi.get(url, params).then((res) => {
          if (res.code === 200) {
            for (let i = 0; i < newVal.length; i++) {
              for (let j = 0; j < res.rows.length; j++) {
                if (newVal[i].clbm === res.rows[j].clbh) {
                  newVal[i].tempFiles.push({
                    ...res.rows[j],
                    name: res.rows[j].filename,
                    url: `${this.$httpApi.baseUrl}/dev-api/fileManageApi/common/fileView/${res.rows[j].fileid}`,
                  });
                  break;
                }
              }
            }
          }
        });
        this.inited = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";

.material {
  border: 1px solid #cccccc;
  box-shadow: 0 0 5px 0 #cccccc;
}
.m-header {
  display: -webkit-flex;
  display: flex;
  column-gap: 20px;
  padding: 20px;
  font-size: 16px;
  color: #e95151;
  font-weight: 600;
}
.required-star {
  margin-right: 5px;
  color: #e95151;
}
::v-deep .el-table__expanded-cell {
  padding-top: 0;
  padding-bottom: 0;

  .ffu-upload {
    border-color: #ffffff;
    color: #ffffff;
    background-color: $color-primary;
  }
}
::v-deep .material-dialog {
  .el-dialog__body {
    height: calc(70vh - 54px - 70px);
  }
}
.dialog-transfer {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  height: 100%;

  .tf-left,
  .tf-right {
    flex: 1;
    height: 100%;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    overflow-y: auto;

    ::v-deep .el-checkbox {
      display: block;
      line-height: 32px;
      &:hover {
        color: $color-primary;
      }
    }
    .tf-checkall {
      padding: 5px 10px;
      border-bottom: 1px solid #eeeeee;
      background-color: #f5f7fa;

      ::v-deep .el-checkbox__label {
        font-size: 16px;
        .tf-checkall-num {
          position: absolute;
          right: 10px;
          font-size: 12px;
        }
      }
    }
    ::v-deep .el-checkbox-group {
      padding-right: 10px;
      padding-left: 10px;

      .el-checkbox {
        width: 100%;
      }
    }
  }
  .tf-right-selected {
    padding-left: 35px;
    line-height: 32px;
    color: #c0c4cc;
    cursor: not-allowed;
  }
}
</style>
