<!--
 * @Author: LXG
 * @Date: 2021-04-27
 * @Editors: LXG
 * @LastEditTime: 2021-06-26
 * @Description: 政策项目申报 - 项目信息
-->
<template>
    <div class="project">
        <h2 class="p-title">
            <p>项目信息</p>
            <!-- <div>
                <el-button
                    v-if="!disabled"
                    size="small"
                    type="primary"
                    @click="exportForm"
                >导出申请表</el-button>
                <el-button
                    v-if="!disabled"
                    size="small"
                    type="primary"
                    @click="showDialog"
                >选择历史表单数据</el-button>
            </div> -->
        </h2>
        <div>
            <ParseFormViewDiv
                v-if="cpu_formdata"
                ref="parseFormViewDiv"
                :formData="cpu_formdata"
                size="100%"
                :readonly="disabled"
            ></ParseFormViewDiv>
        </div>
        <el-dialog
            class="p-dialog"
            :visible.sync="dialogVisible"
            title="历史表单数据选择"
            @open="openDialog"
            @closed="closedDialog"
        >
            <FormInput
                style="margin-bottom: 20px;"
                v-model="searchInfo.data.sxmc"
            >
                <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="handleSearch"
                ></el-button>
            </FormInput>
            <el-table
                v-loading="historyLoading"
                :data="historyData"
                row-class-name="dialog-table-row"
                border
                stripe
                @row-click="selectRow"
            >
                <el-table-column
                    label="序号"
                    type="index"
                    width="55"
                    align="center"
                >
                    <template slot-scope="scope">{{cpu_tableIndex(scope.$index)}}</template>
                </el-table-column>
                <el-table-column
                    label="事项名称"
                    align="center"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.sxmc||''}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="申请时间"
                    align="center"
                    width="180px"
                >
                    <template slot-scope="scope">
                        <span>{{scope.row.createtime.split("T")[0]}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :current-page.sync="pageInfo.num"
                :page-size.sync="pageInfo.size"
                background
                layout="total,prev,pager,next,jumper"
                :total="pageInfo.total"
                @current-change="changePage"
            >
            </el-pagination>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ParseFormViewDiv from '@/components/declareComponents/formMaking/ParseFormViewDiv'
import { FormInput } from '@/components/form/index.js'
import { formatDate } from '@/utils/dateHandler.js'

export default {
    name: 'Project',
    components: {
        ParseFormViewDiv,
        FormInput,
    },
    props: {
        value: {},
        disabled: {
            type: Boolean,
            default: false
        },
    },
    inject: {
        policyData: {
            from: 'policyData',
            default: () => { return {} }
        },
        policyId: {
            from: 'policyId',
            default: ''
        },
        enterpriseData: {
            from: 'enterpriseData',
            default: () => { return {} }
        },
        companyData: {
            from: 'companyData',
            default: () => { return {} }
        },
    },
    data() {
        return {
            searchInfo: {
                data: {
                    sxmc: '', // 事项名称
                },
                oldParams: {},
            },
            pageInfo: {
                num: 1,
                size: 5,
                total: 0,
            },
            dialogVisible: false,
            historyLoading: false,
            historyData: [], // 历史表单数据列表
            companyBankData: {}, // 企业银行信息
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'getUserInfo',
            getDictLabel: 'dictionary/getDictLabel',
        }),
        cpu_value: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        cpu_formdata() {
            if (!this.value.formdata) return ''

            return JSON.parse(this.value.formdata)
        },
        cpu_tableIndex() {
            return function (index) {
                return ((this.pageInfo.num - 1) * this.pageInfo.size) + index + 1
            }
        },
        cpu_policyData() {
            return this.policyData()
        },
        cpu_policyId() {
            return this.policyId()
        },
        cpu_companyData() {
            return this.companyData()
        },
        cpu_enterpriseData() {
            return this.enterpriseData()
        },
    },
    methods: {
        /**
         * @description: 导出申请表
         */
        async exportForm() {
            if (!this.$refs['parseFormViewDiv']) {
                this.$message.error('错误，未读取到项目表单信息')
                return
            }
            let url = this.$httpApi.baseUrl + '/common/download_web'
            let params = {
                delete: true,
                fileName: this.cpu_policyData.name,
                ftlName: `${this.cpu_policyId}.ftl`,
                // ftlName: 'ba_industrial_development.ftl',
            }
            let newFormData = await this.$refs['parseFormViewDiv'].getTempSaveData()
            for (const [k, v] of Object.entries(newFormData)) {
                if (v instanceof Array) {
                    newFormData[k] = v.join(',')
                }
            }
            newFormData.qymc = this.cpu_enterpriseData.unitname // 企业名称
            newFormData.zcsj = formatDate(this.cpu_companyData.zcsj, 'yyyy-MM-dd') // 注册日期
            newFormData.tyxydm = this.cpu_enterpriseData.unitcardcode // 统一社会信用代码
            newFormData.zcdz = this.cpu_companyData.zcdz // 注册地址
            newFormData.zyyw = this.cpu_companyData.jyfw // 主营业务
            newFormData.yydz = this.cpu_companyData.zbdz // 运营地址
            newFormData.yhzh = this.companyBankData.account // 银行账户
            newFormData.khh = this.getDictLabel('sys_acc_bank', this.companyBankData.zhmc) // 开户行
            newFormData.fddbr = this.cpu_companyData.frdb || '' // 法定代表人
            newFormData.lxdh = this.cpu_companyData.legal_person_mobile1 || '' // 法定代表人联系电话
            newFormData.lxr = this.userInfo.nickName // 联系人
            newFormData.lxrdh = this.userInfo.userName // 联系人电话
            newFormData.lxyx = this.cpu_companyData.lgl_youxiang || '' // 联系邮箱
            // 值不能为undefined，遍历检查一下
            for (const [k, v] of Object.entries(newFormData)) {
                if (v == undefined) {
                    newFormData[k] = ''
                }
            }
            params.data = encodeURIComponent(JSON.stringify(newFormData))
            let newUrl = this.getPDFHerf(url, params)
            window.open(newUrl)
        },
        getPDFHerf(fullUrl, params) {
            if (params) {
                fullUrl = fullUrl + "?"
                for (const key in params) {
                    fullUrl += key + "=" + params[key] + "&"
                }
                if (fullUrl.lastIndexOf("&")) {
                    fullUrl = fullUrl.substring(0, fullUrl.length - 1);
                }
                console.log("PDFherf:", fullUrl);
                return fullUrl;
            }
        },
        showDialog() {
            this.dialogVisible = true
        },
        openDialog() {
            this.getProjectHistoryList()
        },
        closedDialog() {
            this.searchInfo.data.sxmc = ''
            this.pageInfo.num = 1
            this.pageInfo.total = 0
            this.historyData = []
        },
        /**
         * @description 搜索历史表单
         */
        handleSearch() {
            this.searchInfo.oldParams = JSON.parse(JSON.stringify(this.searchInfo.data))

            this.pageInfo.num = 1
            this.pageInfo.total = 0
            this.historyData = []
            this.getProjectHistoryList()
        },
        changePage(page) {
            this.historyData = []
            this.getProjectHistoryList()
        },
        selectRow(row, column, e) {
            // console.log('selectRow:', row, column, e)
            let url = `/dev-api/userBusiness/queryFormData?bsnum=${row.id}`
            let params = {}
            this.$httpApi.get(url, params).then(res => {
                if (res.code == 200) {
                    this.cpu_value.formdata = ''
                    this.dialogVisible = false

                    this.$nextTick(() => {
                        this.cpu_value.formdata = res.data.formdata
                        this.cpu_value.subdata = res.data.subdata
                    })
                } else {
                    this.$message.error(`错误：${res.msg}`)
                }
            }).catch(err => {
                console.log(`错误：${err}`)
            })
        },
        /**
         * @description 查询政策项目历史列表
         */
        getProjectHistoryList() {
            // TODO
            // 加上 tyxydm 这个条件，会没有数据，不方便做 历史表单 这个功能
            // 先屏蔽
            let url = '/dev-api/userBusiness/list'
            let params = {
                pageNum: this.pageInfo.num,
                pageSize: this.pageInfo.size,
                sxid: this.$route.query.policyId, // 发现 政策ID 就是这个 sxid
                tyxydm: this.userInfo.tyshxydm, // 统一社会信用代码
                ...this.searchInfo.oldParams,
            }
            this.historyLoading = true
            this.$httpApi.get(url, params).then(res => {
                console.log('getProjectHistoryList:', res)
                this.historyLoading = false
                if (res.code == 200) {
                    this.historyData = res.rows || []
                    this.pageInfo.total = res.total || 0
                }
            }).catch(err => {
                console.log('getProjectHistoryList error:', err)
                this.historyLoading = false
            })
        },
        /**
         * @description 查询企业银行信息
         * @param {String} tyshxydm 统一社会信用代码
         */
        getCompanyBankData(tyshxydm) {
            let url = '/dev-api/enterprise/bank/list'
            let params = {
                pageNum: 1,
                pageSize: 1,
                webEid: tyshxydm,
            }
            this.$httpApi.get(url, params).then(res => {
                // console.log('getCompanyBankData:', res)
                if (res.code == 200 && !!res.rows.length) {
                    this.companyBankData = res.rows[0]
                }
            }).catch(err => {
                console.log('getCompanyBankData error:', err)
            })
        },
    },
    created() {
        this.$store.dispatch('dictionary/commonSetDic', {
            types: ['sys_acc_bank']
        })
        this.getCompanyBankData(this.userInfo.tyshxydm)
    },
    mounted() {
        if (this.cpu_formdata) {
            this.$nextTick(() => {
                this.$refs['parseFormViewDiv'].fillFormData(this.cpu_formdata, JSON.parse(this.cpu_value.subdata || '{}'))
            })
        }
    },
    watch: {
        cpu_formdata(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$refs['parseFormViewDiv'].fillFormData(newVal, JSON.parse(this.cpu_value.subdata || '{}'))
                })
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.p-title {
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #000000;
    padding-bottom: 5px;
    padding-left: 10px;
    border-bottom: 4px solid #000000;
    margin-bottom: 10px;
}
::v-deep .p-dialog {
    .dialog-table-row {
        cursor: pointer;

        &:hover {
            font-weight: bold;
        }
    }
    .el-pagination {
        margin-top: 20px;
        text-align: right;
    }
}
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-input-group__prepend,
::v-deep .el-input__inner,
::v-deep .el-textarea__inner {
    border: none !important;
    background-color: transparent !important;
}
::v-deep input::-webkit-input-placeholder {
  color: transparent !important;
}
::v-deep textarea::-webkit-input-placeholder {
  -webkit-text-fill-color: transparent !important;
}
</style>
