<!--
 * @Author: LXG
 * @Date: 2021-04-27
 * @Editors: LXG
 * @LastEditTime: 2021-06-15
 * @Description: 政策项目申报 - 经办人信息
-->
<template>
  <div class="personal">
    <h2 class="p-title">银行账户<span style="color:red;font-size: 14px;vertical-align: middle;margin-left: 10px;">*信息变更请前往用户中心进行修改</span></h2>
    <div>
      <el-form
        ref="form"
        :model="cpu_value"
        size="medium"
        label-width="100px"
        :rules="formRules"
      >
        <el-form-item prop="bank" label="账户名称">
          <FormInput v-model="cpu_value.bank" :disabled="disabled"></FormInput>
        </el-form-item>
        <el-form-item prop="ssyh" label="所属银行">
          <el-select
            v-model="cpu_value.ssyh"
            placeholder="请选择所属银行"
            clearable
            :style="{ width: '100%' }"
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in ssyhOptions"
              :key="index"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="zhmc" label="支行名称">
          <FormInput
            v-model.number="cpu_value.zhmc"
            :disabled="disabled"
          ></FormInput>
        </el-form-item>
        <el-form-item prop="phone" label="联系电话">
          <FormInput v-model="cpu_value.phone" :disabled="disabled"></FormInput>
        </el-form-item>
        <el-form-item prop="account" label="账号">
          <FormInput
            v-model.number="cpu_value.account"
            :disabled="disabled"
          ></FormInput>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { FormInput } from "@/components/form/index.js";
import { checkIdcard, checkPhone } from "@/utils/check.js";
import { mapGetters } from "vuex";

export default {
  name: "Personal",
  components: {
    FormInput,
  },
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ssdqData: [], //所属地区
      field102Options: [],
      formRules: {
        bank: [
          {
            required: true,
            message: "请输入账户名称",
            trigger: "blur",
          },
        ],
        ssyh: [
          {
            required: true,
            message: "请选择所属银行",
            trigger: "change",
          },
        ],
        zhmc: [
          {
            required: true,
            message: "请输入支行名称",
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
      },
      ssyhOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      accountId: "getAccountId",
    }),
    cpu_value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  created() {
    this.cpu_value.webEid = this.userInfo.tyshxydm;
    this.getList();
  },
  mounted() {
    this.getAccType();
  },
  methods: {
    /**
     * 获取银行列表
     *
     * @param { 页面，页面大小，webEid }
     * @returns { Array }	账户列表
     */
    getList() {
      let url = "/dev-api/enterprise/bank/list";
      let params = {
        pageSize: 100, // 页码大小
        pageNum: 0, // 页码页码
        webEid: this.cpu_value.webEid,
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          console.log("获取银行列表>>>", res);
          if (res.code === 200 && res.rows.length > 0) {
            let resList = JSON.parse(JSON.stringify(res.rows));
            const _that = this;
            _that.allFormData = resList; // 存下所有
            _that.cpu_value = resList[0];
          }
        })
        .catch((err) => {});
    },
    addUp() {
      let url = this.cpu_value.id
        ? "/dev-api/enterprise/bank/update"
        : "/dev-api/enterprise/bank/install";
      let params = this.cpu_value;
      console.log("提交参数>>>", params);
      this.$httpApi
        .postByBody(url, params)
        .then((res) => {
          console.log("返回>>>", res);
          if (res.code === 200) {
            // this.$message({
            //   message: "提交成功！",
            //   type: "success",
            // });
            // this.tijBtnShow = false;
            sessionStorage.setItem('indexActiveName','yhzh');
            // this.$router.go(0);
          } else if (res.code === 500) {
            this.$message({
              message: "参数错误！",
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },
    /**
     * 通用：根据字典类型获取字典信息
     */
    async getDictionaryInfo(el) {
      let response;
      await this.$httpApi
        .get("/dev-api/system/dict/getDictListByType", { dictType: el })
        .then((res) => {
          // console.log("根据字典类型获取字典信息>>>", res)
          // console.log("--------------------------------------------")
          if (res.code == 200 && res.data.length !== 0) {
            response = res;
          } else {
            response = 0;
          }
        })
        .catch((err) => {});
      return response;
    },
    /**
     * 处理数据通用
     */
    async getClassWrap(el, title) {
      let endList = [];
      await this.getDictionaryInfo(el).then((res) => {
        if (res === 0) {
          console.log("字典暂无数据>>>");
        } else {
          // 处理数据
          // console.log(`${title || ''}处理前>>>`, res)
          const resList = JSON.parse(JSON.stringify(res.data));
          let target = [];
          for (const item of resList) {
            target.push({
              id: item.sort, // 0
              value: item.key, // "A"
              label: item.label, // "农、林..."
            });
          }
          // console.log(`获取${title || ''}处理后的数据>>>`, target)
          endList = target;
        }
      });
      return endList;
    },
    /**
     * 所属银行
     */
    getAccType() {
      this.getClassWrap("sys_acc_bank", "获取所属银行").then((res) => {
        // this.field106Options = res
        this.ssyhOptions = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.p-title {
  color: #000000;
  padding-bottom: 5px;
  padding-left: 10px;
  border-bottom: 4px solid #000000;
  margin-bottom: 10px;
}
::v-deep .el-input.is-disabled .el-input__inner,
::v-deep .el-input-group__prepend,
::v-deep .el-input__inner {
  border: none !important;
  background-color: transparent !important;
}
</style>
